import React from 'react';
import styled from 'styled-components';

const CopyrightContainer = styled.div`
  margin-top: 30px;

  & p {
    margin: 0;
  }
`;

const Copyright = props => (
  <CopyrightContainer>
    <p>{props.text}</p>
  </CopyrightContainer>
);

export default Copyright;
