import React from 'react';
import styled from 'styled-components';
import Copyright from '../images/copyright';

const LandscapeImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Landscape = styled.img`
  object-fit: contain;
  height: auto;
  width: calc(100% - 110px);

  max-height: calc(100% - 30px);

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const LandscapeImage = props => (
  <LandscapeImageContainer>
    <Landscape
      src={props.image}
      // srcSet={`${props.image}&h=500&crop=entropy&q=80 1x,
      // ${props.image}&h=500&crop=entropy&dpr=2&q=80 2x,
      // ${props.image}&h=500&crop=entropy&dpr=3&q=80 3x`}
      // src={`${props.image}&h=500&crop=entropy&q=80`}
      width={props.imageWidth}
      height={props.imageHeight}
      alt={props.copyright}
    />
    <Copyright text={props.copyright} />
  </LandscapeImageContainer>
);
export default LandscapeImage;
