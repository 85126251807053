import React from 'react';
import styled from 'styled-components';
import Copyright from '../images/copyright';

const PortraitImageContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;
`;

const Portrait = styled.img`
  object-fit: contain;
  width: auto;
  height: calc(100% - 110px);

  @media (max-width: 767px) {
    height: calc(100% - 200px);
  }
`;

const PortraitImage = props => (
  <PortraitImageContainer>
    <Portrait
      // srcSet={`${props.image}&h=500&crop=entropy&q=80 1x,
      // ${props.image}&h=500&crop=entropy&dpr=2&q=80 2x,
      // ${props.image}&h=500&crop=entropy&dpr=3&q=80 3x`}
      // src={`${props.image}&h=500&crop=entropy&q=80`}
      src={props.image}
      width={props.imageWidth}
      height={props.imageHeight}
      alt={props.copyright}
    />
    <Copyright text={props.copyright} />
  </PortraitImageContainer>
);
export default PortraitImage;
