import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useEffectOnce } from 'react-use';

// import { Col } from 'styled-bootstrap-grid';
// Components
import PortraitImage from '../components/images/portraitImage';
import LandscapeImage from '../components/images/landscapeImage';

const InnerContainer = styled.div`
  height: 100%;
  width: 100%;

  padding: 105px 40px;

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

const Index = ({ data }) => {
  const [randomImage, setRandomImage] = useState(null);
  const gallery = data.prismicHome.data.gallery;

  useEffectOnce(() => {
    const randomNumber = Math.floor(Math.random() * gallery.length);
    setRandomImage(gallery[randomNumber]);
  });

  return (
    <>
      {randomImage !== null && (
        <InnerContainer>
          {randomImage.gallery_image.dimensions.width >
          randomImage.gallery_image.dimensions.height ? (
            <LandscapeImage
              width={randomImage.gallery_image.dimensions.width}
              height={randomImage.gallery_image.dimensions.height}
              image={randomImage.gallery_image.url}
              copyright={randomImage.gallery_image.alt}
            />
          ) : (
            <PortraitImage
              width={randomImage.gallery_image.dimensions.width}
              height={randomImage.gallery_image.dimensions.height}
              image={randomImage.gallery_image.url}
              copyright={randomImage.gallery_image.alt}
            />
          )}
        </InnerContainer>
      )}
    </>
  );
};

export default Index;

export const pageQuery = graphql`
  query Homepage {
    prismicHome {
      data {
        title {
          text
        }
        gallery {
          gallery_image {
            dimensions {
              width
              height
            }
            alt
            url
          }
        }
      }
    }
  }
`;
